:root {
  --color-base: #ecb3b0;
  --color-black: black;
  --color-border-bottom: #ecb3b04f;
  --color-text: black;
  --color-base-font: #ecb3b0;
  --color-black-bold: black;
  --color-btn-hover: #ecb3b0;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .container {
    max-width: 90%;
  }
  .featured-categories-section-list > .item-banner-info {
    height: 200px;
  }
  .item-banner-info-text {
    width: 90%;
  }
  .item-banner-info-text h4 {
    margin-bottom: 25px;
  }
  .item-three-info {
    left: 7px;
    right: 7px;
  }
  .best-sell-section-banner-info h4 {
    font-size: 28px;
    margin-bottom: 30px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1600px;
  }
}
@media (min-width: 765px) and (max-width: 1199px) {
  .header-section-bottom-menu > ul {
    padding-left: 0;
  }
  .featured-categories-section-list {
    flex-wrap: wrap;
  }
  .featured-categories-section-list > .item-info {
    width: 24%;
    margin-bottom: 5px;
  }
  .featured-categories-section-list > .item-banner-info {
    width: 100%;
    margin-right: unset;
    margin-bottom: 10px;
  }
  .popular-section-list > .list-item {
    width: 23%;
    margin-right: 1.8%;
    margin-bottom: 25px;
  }
  .popular-section-list > .list-item:nth-child(5) {
    margin-right: 1.8%;
  }
  .popular-section-list > .list-item:nth-child(4) {
    margin-right: 0;
  }
  .popular-section-list > .list-item:nth-child(8) {
    margin-right: 0;
  }
  .deals-day-section-list {
    flex-wrap: wrap;
  }
  .deals-day-section-item {
    width: 49%;
    margin-right: 2%;
  }
  .deals-day-section-item:nth-child(2) {
    margin-right: unset;
  }
  .deals-day-section-item:nth-child(4) {
    margin-right: unset;
  }

  .login-section {
    padding: 20px;
  }
  .header-section-bottom-cat > button {
    font-size: 12px;
  }
  .header-section-bottom-menu ul li {
    margin-right: 15px;
  }
  .header-section-bottom-help {
    align-items: flex-start;
    font-size: 12px;
  }
  .header-section-bottom-help small {
    font-size: 12px;
  }
  .header-section-logo {
    width: 100%;
  }
  .header-section-logo img {
    width: 100%;
  }
  .header-section-middle-input-group {
    height: 40px;
    overflow: hidden;
  }
  .header-section-middle-input-group input {
    font-size: 12px;
  }
  .header-section-info ul li img {
    margin-right: 5px;
    width: 12px;
  }
  .header-section-info ul li {
    margin-right: 5px;
    font-size: 12px;
  }
  .header-section-middle-input-group select {
    font-size: 13px;
  }
  .footer-section {
    height: auto;
  }
  .footer-section-top {
    height: auto;
  }
  .subscribe-section {
    height: 190px;
  }
  .subscribe-section-item {
    width: 70%;
    padding: 22px 10px 10px 30px;
  }
  .subscribe-section-item > h2 {
    font-size: 22px;
  }

  .subscribe-input > .input-group > .input-group-icon {
    padding: 8px 0px 15px 15px;
    width: 47px;
  }
  .subscribe-input > .input-group {
    height: 40px;
  }
  .subscribe-input > .input-group > input {
    width: calc(100% - 165px);
  }
  .subscribe-input > .input-group > .input-group-subscribe {
    padding: 8px 20px;
    height: 100%;
    width: 120px;
    font-size: 15px;
  }
  .popular-section-list > .list-item {
    width: 31%;
    margin-right: 1.8%;
  }
  .popular-section-list > .list-item:nth-child(3) {
    margin-right: 0;
  }
  .popular-section-list > .list-item:nth-child(6) {
    margin-right: 0;
  }
  .popular-section-list > .list-item:nth-child(4) {
    margin-right: 1.8%;
  }
  .popular-section-list > .list-item:nth-child(8) {
    margin-right: 1.8%;
  }
  .other-section-list {
    flex-wrap: wrap;
  }
  .other-section-item {
    width: 47.7%;
  }
  .best-sell-section-banner {
    width: 30%;
  }
  .best-sell-section-tab {
    width: 68%;
  }
  .product-qty-btn {
    height: 40px;
    width: 106px;
    border-radius: 5px;
  }
  .product-cart-btn {
    height: 40px;
  }
  .product-cart-btn > button {
    padding: 0 15px;
  }
  .product-fav-btn {
    height: 40px;
  }
  .product-detail-section-info > .price > h1 {
    font-size: 40px;
  }
  .detail-sidebar {
    display: none;
  }
  .detail-sidebar-banner {
    display: none;
  }

  .popular-section-list > .list-item-two {
    width: 48.1%;
    margin-right: 3.8%;
    margin-bottom: 25px;
  }

  .popular-section-list > .list-item-two:nth-child(2) {
    margin-right: 0;
  }

  .popular-section-list > .list-item-two:nth-child(4) {
    margin-right: 0;
  }
  .popular-section-list > .list-item-two:nth-child(6) {
    margin-right: 0;
  }
  .popular-section-list > .list-item-two:nth-child(8) {
    margin-right: 0;
  }
  .popular-section-list > .list-item-two:nth-child(10) {
    margin-right: 0;
  }
  .item-img-three img {
    object-fit: cover;
  }
  .best-sell-section-banner-info h4 {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .detail-sidebar {
    display: block;
  }
  .detail-sidebar-banner {
    display: block;
  }
  .blog-section-item {
    height: 530px;
  }
}
@media (max-width: 764px) {
  .header-section-middle {
    padding: 20px 0;
  }
  .header-section-logo {
    position: absolute;
    right: 0;
  }
  .header-section-logo img {
    height: 30px;
  }
  .header-section-info span {
    display: none;
  }
  .header-section-info ul li {
    margin-right: 5px;
  }
  .slider-section {
    height: auto;
  }
  .slider-section img {
    height: 230px;
  }
  .slider-section .carousel-caption > button {
    width: 100px;
    height: 30px;
    font-weight: unset;
    font-size: 13px;
  }
  .subscribe-input {
    width: 100%;
  }
  .slider-section .carousel-caption {
    top: 20px;
  }
  .slider-section .carousel-caption > h3 {
    font-size: 25px;
  }
  .featured-categories-section-list > .item-banner-info {
    height: 200px;
  }
  .item-banner-info-text {
    width: 90%;
  }
  .item-banner-info-text h4 {
    margin-bottom: 20px;
  }
  .featured-categories-section-list {
    flex-wrap: wrap;
  }
  .featured-categories-section-list > .item-info {
    width: 48%;
    margin-right: 2%;
    margin-bottom: 10px;
  }
  .featured-categories-section-list > .item-banner-info {
    width: 100%;
    margin-bottom: 10px;
  }
  .popular-section {
    margin-bottom: 0;
  }
  .popular-section-title h4 {
    width: 100%;
  }
  .popular-section-title-tab {
    top: -20px;
  }
  .popular-section-list > .list-item {
    width: 100%;
    margin-right: 0;
  }
  .popular-section-title-tab > .nav-tabs {
    float: left;
  }

  .popular-section-title-tab > .nav-tabs > a {
    padding-right: 1px;
  }
  .best-sell-section-tab {
    width: 100%;
  }
  .best-sell-section-item-list {
    width: 100%;
  }
  .best-sell-section-banner {
    display: none;
  }
  .deals-day-section-list {
    flex-wrap: wrap;
  }
  .deals-day-section-item {
    width: 100%;
    margin-right: 0;
  }
  .other-section-list {
    flex-wrap: wrap;
  }
  .other-section-item {
    width: 100%;
    margin-right: 0;
  }
  .subscribe-section {
    height: 136px;
  }
  .subscribe-section-item {
    width: 100%;
    padding: 15px;
  }
  .subscribe-section-item > h2 {
    font-size: 14px;
  }
  .subscribe-section-item > p {
    font-size: 10px;
    margin-bottom: 10px;
  }
  .subscribe-input > .input-group {
    height: 30px;
  }
  .subscribe-input > .input-group > .input-group-icon {
    padding: 2px 0px 5px 10px;
    width: 35px;
  }
  .subscribe-input > .input-group > .input-group-subscribe {
    padding: 8px 10px;
    width: 68px;
    font-size: 10px;
  }
  .subscribe-input > .input-group > input {
    width: calc(100% - 101px);
    font-size: 12px;
  }
  .subscribe-input img {
    width: 10px !important;
  }
  .subscribe-section-item-img img {
    object-position: bottom;
    border-bottom-right-radius: 9px;
  }
  .option-section-list {
    flex-wrap: wrap;
  }
  .option-section-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    justify-content: left;
    padding-left: 20px;
  }
  .footer-section-top {
    height: auto;
  }
  .footer-section-bottom {
    height: auto;
    padding-bottom: 20px;
  }
  .footer-section-social {
    justify-content: left;
  }
  .welcome-modal {
    min-height: auto;
    padding: 0;
  }
  .welcome-modal > h2 {
    width: 90%;
  }
  .product-qty-btn {
    height: 35px;
    width: 110px;
  }
  .product-cart-btn {
    height: 35px;
  }
  .product-cart-btn > button {
    padding: 0 20px;
    font-size: 13px;
  }
  .product-fav-btn {
    height: 35px;
  }
  .product-detail-sizeBtn {
    height: 35px;
    width: 35px;
  }
  .product-detail-property {
    padding: 10px;
  }
  .product-detail-property > .nav-tabs > .nav-item {
    margin-right: 5px;
    font-weight: unset;
    padding: 2px 9px;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .footer-section-mobile {
    display: block;
  }
  .footer-mobile {
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0 -2px 10px rgb(0 0 0 / 20%);
    position: fixed;
    bottom: 0;
    display: block;
  }
  .footer-mobile-menu {
    padding-top: 12px;
  }
  .footer-mobile-menu > ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
  }
  .footer-mobile-menu > ul > li {
    display: inline-block;
    position: relative;
    text-align: center;
    width: 25%;
  }
  .footer-mobile-menu > ul > li > a {
    color: var(--color-btn-hover);
  }
  .footer-mobile-menu > ul > li > a > div {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000;
    opacity: 0.7;
  }
  .login-section {
    padding: 10px;
  }
  .popular-section-list > .list-item-two {
    width: 100%;
    margin-right: 0;
  }
  .detail-sidebar {
    display: none;
  }
  .detail-sidebar-banner {
    display: none;
  }
  .item-img img {
    object-fit: contain;
  }
  .category-section-title {
    flex-wrap: wrap;
  }
  .category-section-title p {
    width: 100%;
  }
  .item-img-three img {
    object-fit: cover;
  }
  .close {
    background: unset;
    border: unset;
    color: red;
  }
  .best-sell-section-banner-info h4 {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  .category-section-title select{
    width: 100%;
    margin-bottom: 10px;
    margin-right:0;
  }
}
@media (min-width: 465px) and (max-width: 764px) {
  .best-sell-section-item-list {
    width: 95% !important;
  }
}
