/*Common Section*/
@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600&family=Oxygen&display=swap");
:root {
  --color-base: #ecb3b0;
  --color-black: black;
  --color-border-bottom: #ecb3b04f;
  --color-text: black;
  --color-base-font: #ecb3b0;
  --color-black-bold: black;
  --color-btn-hover: #ecb3b0;
}
body {
  font-family: "Noto Sans", sans-serif;
}
a {
  text-decoration: unset;
}
a:hover {
  color: unset;
}
.position-relative {
  position: relative;
}
.float-right {
  float: right;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 527px !important;
  /* border: 1px solid var(--color-border-bottom); */
  border: unset;
  border-radius: 10px;
  margin-bottom: 20px;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 50px !important;
  width: 60px;
}
.image-gallery-play-button {
  bottom: 10px !important;
}
.image-gallery-fullscreen-button {
  bottom: 10px !important;
}
/*Item Section Start*/
.item {
  width: 100%;
  border: 1px solid var(--color-border-bottom);
  border-radius: 10px;
  height: 400px;
  padding: 15px;
}
.item-about {
  width: 100%;
  border: 1px solid var(--color-border-bottom);
  border-radius: 10px;
  padding: 15px;
}

.item-img {
  height: 60%;
}

.item-img-two {
  height: 46%;
}
.item-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.item-img-two img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.item-img-1 {
  display: block;
}
.item-img-2 {
  display: none;
}

.item-img:hover > .item-img-2 {
  display: block;
}
.item-img:hover > .item-img-1 {
  display: none;
}

.item-img-two:hover > .item-img-2 {
  display: block;
}
.item-img-two:hover > .item-img-1 {
  display: none;
}
.item-info {
  height: 40%;
  color: var(--color-black-bold);
}

.item-info > h5 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--color-black-bold);
  cursor: pointer;
}

.item-info > h5 > a {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--color-black-bold);
}

.item-info > h5:hover {
  color: var(--color-btn-hover);
}

.item-info > h5 > a:hover {
  color: var(--color-btn-hover);
}
.item-info > p {
  margin-bottom: 10px;
}
.item-info > .price {
  display: flex;
  align-items: center;
  position: relative;
}

.item-info > .price > p {
  font-weight: bold;
  margin-bottom: 0;
}
.item-info > .price > a {
  padding: 6px 20px;
  background-color: var(--color-base);
  color: white;
  border-radius: 5px;
  position: absolute;
  right: 0;
}
.item-info > .price > a:hover {
  background-color: var(--color-btn-hover);
}
.item-info > .price > a > img {
  margin-right: 5px;
}

.item-info button {
  width: 100%;
  border: unset;
  height: 35px;
  background-color: var(--color-base);
  border-radius: 5px;
}

.item-info button:hover {
  background-color: var(--color-btn-hover);
}
.item-info button a {
  color: white;
}
.item-info button img {
  margin-right: 10px;
}
.bar {
  margin-bottom: 5px;
  background-color: gray;
  height: 5px;
}
.bar > div {
  height: 5px;
  border-radius: 50px;
  background: var(--color-base);
}
.item-three {
  width: 100%;
  border-radius: 10px;
  height: 460px;
  position: relative;
}
.item-img-three {
  width: 100%;
  height: 335px;
  border-radius: 20px;
  overflow: hidden;
}

.item-img-three img {
  width: 100%;
  height: 100%;
}
.item-three-info {
  position: absolute;
  top: 40%;
  left: 15px;
  right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.timmer {
}
.timmer ul {
  padding-left: 0;
}
.timmer ul li {
  width: 50px;
  height: 50px;
  background-color: white;
  display: inline-flex;
  list-style: none;
  margin-right: 11px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  flex-direction: column;
}

.timmer ul li h6 {
  margin-bottom: 0;
  font-size: 12px;
}
.timmer ul li:last-child {
  margin-right: 0;
}
.item-three-info-list {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  color: var(--color-text);
}

.item-three-info-list:hover {
  color: var(--color-btn-hover);
}
.item-three-info-list > h5 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.item-three-info-list > .price {
  display: flex;
  align-items: center;
  position: relative;
}

.item-three-info-list > .price > p {
  font-weight: bold;
  margin-bottom: 0;
}
.item-three-info-list > .price > a {
  padding: 6px 20px;
  background-color: var(--color-base);
  color: white;
  border-radius: 5px;
  position: absolute;
  right: 0;
  border-radius: 5px;
}
.item-three-info-list > .price > a:hover {
  background-color: var(--color-btn-hover);
}
.item-three-info-list > .price > a > img {
  margin-right: 5px;
}
.item-list {
  width: 100%;
  border: 1px solid var(--color-border-bottom);
  border-radius: 10px;
  height: 200px;
  padding: 15px;
  display: flex;
  align-items: center;
}
.item-list-img {
  width: 20%;
  height: 100%;
}
.item-list-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.item-list-info {
  width: 80%;
  color: var(--color-text);
}
.item-list-info > h5:hover {
  color: var(--color-base-font);
}
.item-list-info > .price {
  display: flex;
  align-items: center;
  position: relative;
}
.item-list-info > .price > p {
  font-weight: bold;
  margin-bottom: 0;
}
.item-list-info > .price > a {
  padding: 6px 20px;
  background-color: var(--color-base);
  color: white;
  border-radius: 5px;
  position: absolute;
  right: 0;
}
.item-list-info > .price > a:hover {
  background-color: var(--color-btn-hover);
}

/*Item Section End*/

/* Header Section Start*/
.header-section {
  margin-bottom: 20px;
}
.header-section-top {
  border-bottom: 1px solid var(--color-border-bottom);
}
.header-section-top-menu ul {
  padding-left: 0;
  margin-bottom: 5px;
}

.header-section-top-menu ul li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
  position: relative;
}

.header-section-top-menu ul li:last-child {
  margin-right: 0px;
}
.header-section-top-menu > ul > li::after {
  content: "";
  position: absolute;
  right: -5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 10px;
  background: var(--color-border-bottom);
}
.header-section-top-menu > ul > li:last-child:after {
  content: "";
  position: absolute;
  right: -5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0x;
  height: 0px;
  background: var(--color-border-bottom);
}
.header-section-top-menu ul li a {
  font-size: 12px;
  color: var(--color-text);
}
.header-section-top-menu ul li a:hover {
  color: var(--color-btn-hover);
}
.header-section-middle {
  padding: 40px 0;
  border-bottom: 1px solid var(--color-border-bottom);
}

.header-section-bottom.position-higher {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1002;
  background: white;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 5%);
}

.fake-height.position-higher {
  height: 45px;
}

.position-lower .top-header {
  height: 50px;
}

.header-section-middle-cat {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-section-middle-input-group {
  width: 100%;
  height: 50px;
  border: 1px solid var(--color-border-bottom);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.header-section-middle-input-group select {
  width: 19%;
  border: unset;
  font-size: 18px;
  color: var(--color-black-bold);
}
.header-section-middle-input-group > svg {
  margin-right: 10px;
  opacity: 0.7;
}
.header-section-middle-input-group select:focus {
  outline: unset;
  border-color: unset;
  box-shadow: unset;
}
.header-section-middle-input-group input {
  width: 80%;
  border: unset;
  border-left: 1px solid var(--color-border-bottom);
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  font-size: 18px;
  color: var(--color-black-bold);
}
.header-section-middle-input-group input:focus-visible {
  outline: unset;
  box-shadow: unset;
}

.header-section-middle-input-group input:focus {
  border-color: var(--color-border-bottom);
}
.header-section-search-btn {
  border: unset;
  background-color: unset;
}
.header-section-info {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: 100%;
}

.header-section-info ul {
  height: 30px;
  margin-bottom: 0;
  padding-left: 0;
}
.header-section-info ul li {
  display: inline-flex;
  align-items: center;
  list-style: none;
  margin-right: 15px;
}

.header-section-info ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin-right: 15px;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-black-bold);
}
.header-section-info ul li:last-child {
  margin-right: 0px;
}

.header-section-bottom {
  padding: 10px 0px;
  border-bottom: 1px solid var(--color-border-bottom);
}
.header-section-bottom-cat {
  background-color: var(--color-base);
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
}
.header-section-bottom-cat > button {
  border: unset;
  background: unset;
  width: 90%;
  text-align: left;
  color: white;
  font-weight: bold;
}

.header-section-bottom-cat select option {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.header-section-bottom-cat select:focus {
  background-color: unset;
  color: var(--color-black-bold);
  outline: unset;
  border-color: unset;
  box-shadow: unset;
}
.header-section-bottom-menu {
  display: flex;
  height: 100%;
  align-items: center;
}

.header-section-bottom-menu > ul {
  margin-bottom: 0;
}

.header-section-bottom-menu > ul > li {
  display: inline-block;
  list-style: none;
  margin-right: 35px;
}

.header-section-bottom-menu > ul > li > a {
  font-size: 15px;
  font-weight: bold;
  color: var(--color-black-bold);
}
.header-section-bottom-menu > ul > li > a > svg {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.header-section-bottom-menu > ul > li > a:hover {
  color: var(--color-base-font);
  text-decoration: none;
}
.has-sub-menu {
  position: relative;
}

.has-sub-menu:hover > .header-section-sub-menu {
  display: block;
}
.header-section-sub-menu {
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  padding: 10px 15px;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
  margin-top: 20px;
  border-radius: 10px;
  z-index: 999;
  border: 1px solid #ececec;
  -webkit-box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  display: none;
  top: 15px;
}
.header-section-sub-menu:before {
  content: "";
  position: absolute;
  top: -13px;
  left: 10px;
  transition: top 0.35s ease;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
}
.header-section-sub-menu > li {
  list-style: none;
  margin-bottom: 15px;
}
.header-section-sub-menu > li > a {
  font-size: 15px;
  font-weight: bold;
  color: var(--color-black-bold);
}

.header-section-sub-menu > li > a:hover {
  color: var(--color-base-font);
  text-decoration: none;
}
.header-section-bottom-help {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-base-font);
}

.header-section-bottom-help small {
  font-size: 12px;
  color: var(--color-base-font);
  position: relative;
}
/*Header Section End*/

/*Slider Section Start*/
.slider-section {
  height: 350px;
  margin-bottom: 70px;
}
.slider-section img {
  height: 350px;
  object-fit: cover;
  object-position: top;
  border-radius: 20px;
}
.slider-section .carousel-caption {
  right: unset;
  top: 50px;
  bottom: unset;
  left: 10%;
  text-align: left;
  color: var(--color-black-bold);
}
.slider-section .carousel-caption > h3 {
  font-size: 48px;
  color: white;
}

.slider-section .carousel-caption > p {
  color: white;
}
.slider-section .carousel-caption > button {
  border: unset;
  background-color: var(--color-base-font);
  width: 150px;
  height: 50px;
  color: white;
  font-weight: bold;
  border-radius: 50px;
}
/*Slider Section End*/

/*Featured categories section Start*/
.featured-categories-section {
  margin-bottom: 70px;
}

.featured-categories-section-list {
  display: flex;
}
.featured-categories-section > .title > h4 {
  font-weight: 600;
}
.featured-categories-section-list > .item-info {
  width: 9.1%;
  margin-right: 1%;
  background-color: #cb8d8a40;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.featured-categories-section-list > .item-info > p {
  margin-bottom: 0;
}
.featured-categories-section-list > .item-info > img {
  object-fit: contain;
  width: 80px;
  height: 80px;
}
.featured-categories-section-list > .item-info:last-child {
  margin-right: 0;
}
.featured-categories-section-list > .item-banner-info {
  width: 33.1%;
  margin-right: 1%;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.item-banner-info-text {
  position: absolute;
  top: 25%;
  padding-left: 40px;
  width: 70%;
}

.item-banner-info-text h4 {
  margin-bottom: 50px;
  color: white;
}

.item-banner-info-text a {
  color: white;
  background-color: var(--color-base-font);
  padding: 7px 8px 10px 12px;
  font-size: 12px;
  border-radius: 4px;
}
.featured-categories-section-list > .item-banner-info img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
}
.featured-categories-section-list > .item-banner-info:last-child {
  margin-right: 0;
}
/*Featured categories section End*/

/*Popular section Start*/
.popular-section {
  margin-bottom: -20px;
}
.popular-section-title {
  margin-bottom: 20px;
  display: flex;
}

.popular-section-title h4 {
  width: 30%;
  font-weight: 600;
}
.popular-section-title-tab {
  width: 100%;
  position: relative;
  top: -60px;
}
.popular-section-title-tab > .nav-tabs {
  float: right;
  border: unset;
}
.popular-section-title-tab > .nav-tabs a {
  border: unset;
  color: var(--color-text);
}

.popular-section-title-tab > .nav-tabs .active {
  color: var(--color-base-font);
}
.popular-section-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.popular-section-list > .list-item {
  width: 18.5%;
  margin-right: 1.8%;
  margin-bottom: 25px;
}

.popular-section-list > .list-item:nth-child(5) {
  margin-right: 0;
}

.popular-section-list > .list-item:nth-child(10) {
  margin-right: 0;
}

.popular-section-list > .list-item-two {
  width: 23.6%;
  margin-right: 1.8%;
  margin-bottom: 25px;
}

.popular-section-list > .list-item-two:nth-child(4) {
  margin-right: 0;
}

.popular-section-list > .list-item-two:nth-child(8) {
  margin-right: 0;
}
/*Popular section End*/

/*BestSell section End*/
.best-sell-section {
  margin-bottom: 70px;
}

.best-sell-section-title {
  margin-bottom: 20px;
}
.best-sell-section-title > h4 {
  font-weight: 600;
}
.best-sell-section-list {
  display: flex;
  flex-wrap: wrap;
  width: 102%;
}
.best-sell-section-banner {
  width: 24%;
  margin-right: 2%;
  position: relative;
  height: 400px;
  border-radius: 15px;
  overflow: hidden;
}

.best-sell-section-banner img {
  width: 100%;
  height: 100%;
}

.best-sell-section-banner-info {
  position: absolute;
  top: 80px;
  left: 30px;
  right: 20px;
}

.best-sell-section-banner-info h4 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 30px;
  line-height: 54px;
}

.best-sell-section-banner-info a {
  background-color: var(--color-base);
  color: white;
  font-weight: bold;
  padding: 6px 20px;
  border-radius: 5px;
}
.best-sell-section-banner-info a:hover {
  background-color: var(--color-btn-hover);
}
.best-sell-section-item {
  width: 100%;
}

.best-sell-section-item-list {
  width: 90%;
}

.best-sell-section-tab {
  width: 74%;
  position: relative;
}
.best-sell-section-tab > .nav-tabs {
  float: right;
  border: unset;
}
.best-sell-section-tab > .nav-tabs a {
  border: unset;
}
.best-sell-section-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.best-sell-section-list > .list-item {
  width: 18.5%;
  margin-right: 1.8%;
  margin-bottom: 25px;
}
.best-sell-section-tab .react-multi-carousel-list {
  width: 102%;
  padding-bottom: 1px;
}

/*BestSell section End*/
/*Deals Day section End*/
.deals-day-section {
  margin-bottom: 70px;
}
.deals-day-section-title {
  margin-bottom: 20px;
}
.deals-day-section-title > h4 {
  font-weight: 600;
}
.deals-day-section-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.deals-day-section-item {
  width: 23%;
  margin-right: 2.5%;
}

.deals-day-section-item:last-child {
  margin-right: 0;
}
.deals-day-section-item-vendor {
  width: 31%;
  margin-right: 3.3%;
}

.deals-day-section-item-vendor:nth-child(3) {
  margin-right: 0;
}
/*Deals Day section End*/
/*Other section End*/
.other-section {
  margin-bottom: 70px;
}
.other-section-title {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-border-bottom);
}
.other-section-title > h4 {
  font-weight: 600;
}
.other-section-list {
  display: flex;
  width: 100%;
}
.other-section-item {
  width: 23%;
  margin-right: 2.3%;
}
.other-section-item:last-child {
  margin-right: 0;
}
.other-section-item-sub {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.other-section-item-sub-img {
  width: 30%;
  background-color: #f7f8fa;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.other-section-item-sub-img img {
  width: 100%;
}

.other-section-item-sub-info {
  width: 70%;
  padding: 10px 10px 0 10px;
  color: var(--color-text);
}

.other-section-item-sub-info:hover {
  color: var(--color-btn-hover);
}
.other-section-item-sub-info h5 {
  font-size: 16px;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.other-section-item-sub-info small {
  font-size: 12px;
}

.other-section-item-sub-info h6 {
  font-size: 14px;
}
/*Other Day section End*/

/*Subscribe section Start*/

.subscribe-section {
  margin-bottom: 70px;
  background-image: url("../images/banner-10_bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  display: flex;
  border-radius: 20px;
}
.subscribe-section-item {
  width: 50%;
  padding: 50px;
  height: 100%;
}

.subscribe-section-item-img {
  width: 50%;
  height: 100%;
  padding-top: 30px;
}

.subscribe-section-item-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.subscribe-input > .input-group {
  border: unset;
  background: white;
  border-radius: 50px;
  height: 60px;
  align-items: center;
}

.subscribe-input > .input-group > .input-group-icon {
  background: unset;
  border: unset;
  padding: 15px 0px 15px 24px;
  border-radius: 50px;
  height: 100%;
  width: 65px;
}

.subscribe-input > .input-group > .input-group-subscribe {
  background: var(--color-base);
  border: unset;
  padding: 15px 52px;
  border-radius: 50px;
  height: 100%;
  width: 190px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.subscribe-input > .input-group > input {
  background: unset;
  border: unset;
  width: calc(100% - 253px);
}

.subscribe-input > .input-group > input:focus-visible {
  outline: unset;
}
/*Subscribe section End*/

/*Option section Start*/
.option-section {
  margin-bottom: 70px;
}
.option-section-list {
  display: flex;
  width: 100%;
}
.option-section-item {
  width: 19%;
  margin-right: 1.5%;
  background-color: #ecb3b047;
  height: 110px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-section-item img {
  width: 19%;
  margin-right: 10px;
}

.option-section-item > div > h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}

.option-section-item > div > small {
  opacity: 0.5;
}
.option-section-item:last-child {
  margin-right: 0;
}
/*Option section End*/

/*Footer Section Start*/
.footer-section {
  width: 100%;
  height: 367px;
}
.footer-section-mobile {
  display: none;
}
.footer-section-top {
  height: 300px;
  border-bottom: 1px solid var(--color-border-bottom);
  margin-bottom: 10px;
}

.footer-logo {
  margin-bottom: 15px;
}

.footer-section-intro p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  opacity: 0.8;
}

.footer-section-menu > h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
}

.footer-section-menu-devider {
  width: 60px;
  height: 2px;
  background: var(--color-border-bottom);
}

.footer-section-menu > ul {
  padding-left: 0;
}

.footer-section-menu > ul > li {
  list-style: none;
}

.footer-section-menu > ul > li > a {
  font-weight: 400;
  font-size: 16px;
  line-height: 44px;
  opacity: 0.8;
  text-decoration: none;
  color: var(--color-text);
}

.footer-section-menu > ul > li > a:hover {
  color: var(--color-base-font) !important;
}

.footer-section-bottom {
  height: 64px;
  display: flex;
  align-items: center;
}

.footer-section-copy-right {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.footer-section-copy-right p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #010e2f;
  height: 30px;
  margin-bottom: 0;
}

.footer-section-social {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: 100%;
}
.footer-section-social ul {
  padding-left: 0;
  height: 40px;
  margin: unset;
}

.footer-section-social ul li {
  list-style: none;
  display: inline-flex;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-base);
  border-radius: 50px;
}

.footer-section-social ul li > a {
  height: 30px;
}
.footer-section-social ul li:last-child {
  margin-right: 0;
}
.footer-section-social ul li:hover {
  background-color: var(--color-base-font);
}
/*Footer Section End*/

/*Breadcrumb Section Start*/
.breadcrumb-section {
  border-bottom: 1px solid var(--color-border-bottom);
  margin-bottom: 50px;
}
.breadcrumb-section ul {
  padding-left: 0;
}
.breadcrumb-section ul li {
  display: inline-flex;
  list-style: none;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}
.breadcrumb-section ul li img {
  width: 10px;
  margin-left: 10px;
  margin-top: 0;
}
.breadcrumb-section ul li:last-child img {
  display: none;
}
/*Product Detail Section*/
.product-detail-section-info {
  padding-left: 20px;
}
.product-detail-section-img {
  height: auto;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.product-detail-section-info > button {
  border: unset;
  background-color: rgba(255, 0, 0, 0.295);
  color: red;
  border-radius: 5px;
  width: 90px;
  height: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}
.product-detail-section-info > h2 {
  font-weight: bold;
}
.product-detail-section-info > span {
  display: inline-block;
  margin-bottom: 20px;
}
.product-detail-section-info > .price {
  display: flex;
  margin-bottom: 20px;
}
.product-detail-section-info > .price {
  display: flex;
  margin-bottom: 20px;
}
.product-detail-section-info > .price > h1 {
  font-size: 50px;
  font-weight: bold;
  color: var(--color-base-font);
}
.product-detail-section-info > p {
  font-size: 17px;
  opacity: 0.7;
}
.product-detail-btn-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.product-qty-btn {
  height: 50px;
  width: 127px;
  border: 1px solid var(--color-border-bottom);
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
}
.product-qty-btn button {
  width: 33.33%;
  border: none;
  background: none;
}
.product-cart-btn {
  height: 50px;
  box-sizing: border-box;
  margin-right: 10px;
}
.product-cart-btn > button {
  margin: 0 !important;
  top: 0 !important;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: none;
  border: 1px solid var(--color-border-bottom);
  padding: 0 30px;
}
.product-fav-btn {
  height: 50px;
  width: 50px;
  border: 1px solid var(--color-border-bottom);
  box-sizing: border-box;
  padding: 9px 10px 6px 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-image: url("../icons/IconLove.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}
.product-fav-btn-white {
  height: 50px;
  width: 50px;
  border: none;
  box-sizing: border-box;
  padding: 9px 10px 6px 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: red;
  background-image: url("../icons/IconLoveWhite.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.product-fav-btn:hover {
  height: 50px;
  border: none;
  box-sizing: border-box;
  padding: 9px 10px 6px 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: red;
  background-image: url("../icons/IconLoveWhite.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.product-detail-size {
  margin-bottom: 20px;
}
.product-detail-size-title {
  width: 200px !important;
}
.product-detail-sizeBtn {
  margin-right: 10px;
  height: 40px;
  width: 40px;
  font-size: 10px;
  display: inline-block;
  padding: 0;
  border-radius: 50%;
  background-color: unset;
  border: 1px solid var(--color-border-bottom);
}
.product-detail-section-info > .other > table > tr > td {
  padding: 5px 5px 5px 0;
}
.product-detail-section-info > .other > table > tr > td:nth-child(2) {
  color: var(--color-base-font);
}
.product-detail-section-info > .other > table > tr > td:nth-child(4) {
  color: var(--color-base-font);
}
.product-detail-property {
  min-height: 500px;
  border-radius: 10px;
  border: 1px solid var(--color-border-bottom);
  padding: 20px;
}
.product-detail-property > .nav-tabs {
  border: unset;
}
.product-detail-property > .nav-tabs > .nav-item {
  border: 1px solid var(--color-border-bottom);
  border-radius: 50px;
  margin-right: 20px;
  font-weight: bold;
  color: var(--color-black-bold);
  opacity: 0.6;
}
.product-detail-property > .nav-tabs > .active {
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  color: var(--color-base-font);
}
/*detail-sidebar Start*/
.detail-sidebar {
  width: 100%;
  border: 1px solid var(--color-border-bottom);
  border-radius: 15px;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  padding: 30px;
  margin-bottom: 30px;
}
.detail-sidebar-title {
  border-bottom: 1px solid var(--color-border-bottom);
  padding-bottom: 20px;
  font-size: 24px;
  margin-bottom: 20px;
}

.detail-sidebar-category-list {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--color-border-bottom);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.detail-sidebar-category-list:last-child {
  margin-bottom: 0;
}

.detail-sidebar-category-list img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.detail-sidebar-category-list a {
  width: calc(100% - 50px);
  margin-right: 10px;
}

.detail-sidebar-category-list h5 {
  font-size: 15px;
  color: var(--color-black);
}

.detail-sidebar-category-list h5:hover {
  color: var(--color-btn-hover);
}
.detail-sidebar-category-list div {
  width: 35px;
  height: 30px;
  border-radius: 20px;
  background-color: var(--color-base);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 12px;
  color: white;
}
.detail-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border-bottom);
}

.detail-sidebar-item:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: unset;
}
.detail-sidebar-item-img {
  width: 30%;
  margin-right: 10px;
}

.detail-sidebar-item-img img {
  width: 100%;
  object-fit: contain;
}

.detail-sidebar-item-info {
  width: 70%;
}
.detail-sidebar-item-info h5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  font-size: 16px;
  color: var(--color-black);
}

.detail-sidebar-item-info h5:hover {
  color: var(--color-btn-hover);
}
.detail-sidebar-item-info h6 {
  font-size: 14px;
}

.detail-sidebar-filter > ul {
  padding-left: 5px;
}
.detail-sidebar-filter > ul > li {
  list-style: none;
}

.detail-sidebar-filter > button {
  border: none;
  background-color: var(--color-base);
  padding: 5px 25px;
  border-radius: 5px;
  color: white;
}
.detail-sidebar-banner {
  position: relative;
  height: 400px;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
}
.detail-sidebar-banner > img {
  width: 100%;
}
/*detail-sidebar End*/

/*Detail Reviews & Comment Start*/
.detail-comment-section {
}
.detail-comment-section .single-comment {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--color-border-bottom);
  display: flex;
}
.single-comment > .single-comment-img {
  width: 20%;
  padding: 10px 20px 0px 10px;
  text-align: center;
}
.single-comment > .single-comment-img img {
  width: 80%;
  border-radius: 50%;
  margin-bottom: 10px;
}
.single-comment > .single-comment-img p {
  color: var(--color-btn-hover);
  font-size: 12px;
  margin-bottom: 0;
}
.single-comment > .single-comment-des {
  width: 80%;
}
.single-comment > .single-comment-des > span {
  font-size: 10px;
  width: 100%;
}
.single-comment > .single-comment-des > span > small {
  float: right;
}
.single-comment > .single-comment-des > p {
  font-size: 12px;
}
.detail-comment-section .review-list {
  padding: 15px 15px 15px 0;
}
.review-list > .single-review-list {
  display: flex;
  height: 15px;
  margin-bottom: 15px;
}
.review-list > .single-review-list > p {
  width: 30%;
  margin-bottom: 0;
  font-size: 10px;
}
.review-list > .single-review-list > .single-review-bar {
  background-color: #8181813d;
  width: 100%;
  height: 100%;
}
.review-list
  > .single-review-list
  > .single-review-bar
  > .single-review-bar-fill {
  background-color: var(--color-base);
  height: 100%;
}
/*Detail Reviews & Comment End*/

/*welcome-modal section start*/
.welcome-modal {
  width: 100%;
  background-image: url(../images/welcome-1.png);
  background-color: #fff;
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  min-height: 420px;
  padding: 0 50px 50px 50px;
}
.welcome-modal-header {
  justify-content: end;
  border: none;
  padding-bottom: 0;
}

.welcome-modal-header > button {
  border: none;
  background: none !important;
}

.welcome-modal-header > button > span:nth-child(1) {
  font-size: x-large;
}
.welcome-modal-header > button > span:nth-child(2) {
  display: none;
}

.welcome-modal-time > ul {
  padding-left: 0;
}
.welcome-modal-time > ul > li {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  list-style: unset;
  margin-right: 15px;
  width: 63px;
  height: 70px;
  border: 1px solid var(--color-border-bottom);
  background-color: #ffffff;
  border-radius: 5px;
}

.welcome-modal > h6 {
  margin-bottom: 20px;
  color: var(--color-btn-hover);
}

.welcome-modal > h2 {
  margin-bottom: 30px;
  width: 55%;
}
.welcome-modal > h1 {
  margin-bottom: 30px;
}
.welcome-modal > small {
  margin-bottom: 30px;
  display: inline-block;
}

.welcome-modal > .rating {
  margin-bottom: 50px;
}
.welcome-modal > button {
  width: 150px;
  height: 40px;
  background-color: var(--color-base-font);
  color: white;
  border: none;
  border-radius: 5px;
}
/*welcome-modal section End*/
/*cart detail section start*/
.cart-detail-list {
  margin-bottom: 20px;
}

.cart-detail-list > button {
  width: 50px;
  height: 50px;
  background-color: unset;
  border: unset;
  color: red;
  width: 150px;
}
.cart-detail-list > button:last-child {
  width: 150px;
  height: 50px;
  background-color: var(--color-base);
  border: unset;
  color: white;
  border-radius: 5px;
}
.cart-detail-list > table {
  width: 100%;
  margin-bottom: 20px;
}
.cart-detail-list > table > thead {
  background-color: var(--color-base);
  overflow: hidden;
}

.cart-detail-list > table > thead > th {
  padding: 15px;
  overflow: hidden;
  white-space: nowrap;
}

.cart-detail-list > table > thead > th:first-child {
  border-top-left-radius: 15px;
  overflow: hidden;
}

.cart-detail-list > table > thead > th:last-child {
  border-top-right-radius: 15px;
  overflow: hidden;
}

.cart-detail-list > table > tbody {
  border: 1px solid var(--color-border-bottom);
}
.cart-detail-list > table > tbody > tr > td {
  padding: 15px;
  text-align: center;
}
.cart-detail-list > table > tbody > tr > td:nth-child(2) {
  padding: 15px;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 68px;
}
.cart-detail-list > table > tbody > tr > td:nth-child(2) > img {
  width: 50px;
}
.cart-detail-summery {
  width: 100%;
  border: 1px solid var(--color-border-bottom);
  border-radius: 15px;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  padding: 30px;
  margin-bottom: 30px;
}

.cart-detail-summery-title {
}

.cart-detail-summery > table {
  width: 100%;
}
.cart-detail-summery > table > tbody > tr > td {
  padding: 15px;
}

.cart-detail-summery > table > tbody > tr > td:last-child {
  text-align: right;
}

.cart-detail-summery > button {
  width: 100%;
  border: none;
  height: 50px;
  background-color: var(--color-base);
  color: white;
  border-radius: 5px;
}
.checkout-summary-top {
  display: flex;
  height: 25px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
.checkout-summary-top > p {
  color: #00071b;
  width: 75%;
}
.checkout-summary-top > button,
.checkout-summary-top > p {
  font-size: 16px;
  line-height: 23px;
}
.checkout-summary-top > button {
  width: 25%;
  border: none;
  background: none;
  text-align: right;
  text-transform: uppercase;
  color: var(--color-btn-hover);
}
.checkout-summary-address {
  display: flex;
  flex-wrap: wrap;
}
.address-type-btn {
  width: 70px;
  height: 30px;
  background: var(--color-base);
  border-radius: 15px;
  border: none;
  font-size: 12px;
  line-height: 17px;
  color: #fff;
  margin-right: 10px;
}
.checkout-summary-address > p,
.checkout-summary-note {
  font-size: 14px;
  line-height: 20px;
}
.checkout-summary-divider {
  border-bottom: 1px solid var(--color-border-bottom);
  margin-bottom: 20px;
}
/*cart detail section end*/

/* Confirm Modal */
.modal-confirm {
  color: #636363;
  width: 325px;
  font-size: 14px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}
.modal-confirm .form-control,
.modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -5px;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}
.modal-confirm .icon-box {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: var(--color-btn-hover);
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-confirm .icon-box svg {
  font-size: 50px;
  position: relative;
  top: 3px;
}
.modal-confirm.modal-dialog {
  margin-top: 80px;
}
.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: var(--color-btn-hover);
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  width: 100%;
}
.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
  background: var(--color-base-font);
  outline: none;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}
/* Confirm Modal End*/
/*User user-section-menu Start*/

.user-section {
  margin-bottom: 50px;
}

.user-section-title {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: bold;
  color: var(--color-base-font);
}
.user-section-menu > ul {
  padding-left: 0;
}
.user-section-menu > ul > li {
  list-style: none;
  width: 100%;
  height: 50px;
  border: 1px solid var(--color-border-bottom);
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-section-menu > ul > li:hover {
  background-color: var(--color-base);
  border: unset;
}

.user-section-menu > ul > .active-menu {
  background-color: var(--color-base);
  border: unset;
}
.user-section-menu > ul > li > a {
  font-size: 18px;
  color: var(--color-base-font);
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 20px;
}

.user-section-menu > ul > li:hover > a {
  color: white;
}

.user-section-menu > ul > .active-menu > a {
  color: white;
}
.user-section-profile {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--color-border-bottom);
  padding-bottom: 10px;
}
.user-section-profile > label {
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
}
.user-section-profile > input {
  width: calc(100% - 150px);
  border: unset;
  padding-left: 10px;
}
.user-section-profile > input:focus-visible {
  outline: unset;
}
.user-section-profile > button {
  width: 150px;
  border: unset;
  background-color: var(--color-base);
  height: 40px;
  color: white;
}

.user-section-order > table {
  width: 100%;
  margin-bottom: 20px;
}
.user-section-order > table > thead {
  background-color: var(--color-base);
  overflow: hidden;
}

.user-section-order > table > thead > th:first-child {
  border-top-left-radius: 15px;
  overflow: hidden;
}
.user-section-order > table > thead > th:last-child {
  border-top-right-radius: 15px;
  overflow: hidden;
}
.user-section-order > table > thead > th {
  padding: 15px;
  overflow: hidden;
  color: white;
}

.user-section-order > table > tbody {
  border: 1px solid var(--color-border-bottom);
}
.user-section-order > table > tbody > tr > td {
  padding: 15px;
  text-align: center;
}
.user-section-order > table > tbody > tr > td:nth-child(1) {
  padding: 15px;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 68px;
}
.user-section-order > table > tbody > tr > td:nth-child(1) > img {
  width: 50px;
}

.user-section-order > button {
  width: 150px;
  border: unset;
  background-color: var(--color-base);
  color: white;
  height: 40px;
}
/*User user-section-menu End*/
/*Dashboard Profile Start*/
.dashboard-profile {
  width: 100%;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  border: 1px solid var(--color-border-bottom);
  border-radius: 10px;
  padding: 20px;
}

.dashboard-history {
  width: 100%;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  border: 1px solid var(--color-border-bottom);
  border-radius: 10px;
  padding: 20px;
}

.dashboard-history h5 {
  color: var(--color-base);
}
.dashboard-history > table > thead {
  background-color: var(--color-base);
}

.dashboard-history > table > thead > th {
  padding: 10px 5px;
  color: white;
}
.dashboard-history > table > tr > td {
  padding: 10px 0;
}
.dashboard-history img {
  width: 50px;
}
.dashboard-profile,
.dashboard-history > table {
  width: 100%;
}
.dashboard-profile h5 {
  color: var(--color-base);
}
.dashboard-profile > table > tr > td {
  padding: 10px 0;
  width: 50%;
}
/*Dashboard Profile End*/
/*User Info Box Start*/
/*Cart SideBar Section*/

.top-box {
  z-index: 2;
  opacity: 0;
  width: 300px;
  visibility: hidden;
  right: 5px;
  position: absolute;
}

.top-box.show {
  opacity: 1;
  visibility: visible;
}

.top-box:before {
  content: "";
  position: absolute;
  top: -13px;
  right: 16px;
  transition: top 0.35s ease;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
}

.top-box > div {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 3px 9px -1px #999;
}

.top-box-foreground {
  width: 100%;
  height: 100%;
  display: none;
  left: 0;
  top: 0;
  cursor: auto;
  z-index: 1;
}

.top-box-foreground.show {
  display: block;
  position: fixed;
}
.profile-sidebar {
  padding: 20px 0 10px;
  background: #fff;
}
.profile-user-circle {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  background: var(--color-base-font);
  margin: auto !important;
  align-items: center;
  justify-content: center;
  display: flex;
}
.profile-user-circle > img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}
.profile-usertitle-name {
  color: #5a7391;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}
.profile-usertitle-job {
  text-transform: uppercase;
  color: red;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}
.profile-usermenu {
  margin-top: 30px;
}
.profile-usermenu > ul {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}
.profile-usermenu > ul > li {
  line-height: 45px;
  margin: 2px 20px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 3px;
  transition: all 0.5s;
}
.profile-usermenu > ul > li > a {
  color: #373737;
  display: block;
}

.profile-usermenu > ul > li > a > span {
  margin-left: 10px;
}
/*User Info Box End*/
/*Login Section Start*/
.login-section {
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  border: 1px solid var(--color-border-bottom);
  border-radius: 10px;
  padding: 50px;
  margin-bottom: 50px;
}
.login-section > h4 {
  text-align: center;
  margin-bottom: 20px;
}

.login-section > h4 > span {
  float: right;
  font-size: 12px;
}

.login-section > h4 > span > a {
  border: unset !important;
  background: unset !important;
  color: red !important;
}

.login-section label {
  width: 100%;
  font-size: 16px;
  margin-bottom: 10px;
}
.login-section input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid var(--color-border-bottom);
  padding-left: 10px;
}

.login-section input:focus-visible {
  outline: unset;
}
.login-section button {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  border: unset;
  color: white;
  font-weight: bold;
}

.login-section .login-btn {
  background-color: var(--color-btn-hover);
}

.login-section .fb-btn {
  background-color: #3b5998;
}
.login-section .google-btn {
  background-color: #d34836;
}
.login-section small {
  width: 100%;
  margin-bottom: 20px;
  font-size: 15px;
  display: block;
}

.login-section .form-check > input {
  width: 20px;
  height: 20px;
  margin-bottom: 20px;
  font-size: 15px;
  display: block;
  margin-right: 10px;
}

.login-section .form-check > label {
  width: calc(100% - 10px);
}
/*Login Section End*/
/*Category Box Start*/
.category-box {
  z-index: 2;
  opacity: 0;
  width: 500px;
  visibility: hidden;
  position: absolute;
}
.category-box.show {
  opacity: 1;
  visibility: visible;
}
.category-box > div {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 3px 9px -1px #999;
}
.category-sidebar {
  padding: 20px;
  background: #fff;
  display: flex;
  padding-bottom: 0;
}
.category-sidebar ul {
  padding-left: 0;
  width: 48%;
  margin-right: 4%;
  margin-bottom: 0;
}
.category-sidebar ul:last-child {
  margin-right: 0;
}
.category-sidebar ul li {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 48px;
  border-radius: 5px;
  border: 1px solid #f2f3f4;
  padding: 9px 18px;
  height: 50px;
  margin-bottom: 20px;
  color: red;
}
.category-sidebar ul li a {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  line-height: 1.5;
  color: #253d4e;
  font-size: 14px;
}
.category-sidebar ul li:hover {
  border: 1px solid var(--color-base);
  -webkit-box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
}
/*Category Box End*/
/*Category Section Start*/
.category-section-title {
  display: inline-flex;
  width: 100%;
}

.category-section-title p {
  width: calc(100% - 420px);
}
.category-section-title select {
  float: right;
  margin-right: 20px;
  width: 200px;
  border-radius: 10px;
  border: 1px solid var(--color-border-bottom);
  padding: 10px;
  color: var(--color-base-font);
}
.category-section-title select:last-child {
  margin-right: 0;
}
.category-section-title select:focus-visible {
  outline: unset;
}
/*Category Section End*/

/*MODAL FADE LEFT BOTTOM */

.modal.left {
}

.modal.left .modal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  min-width: 300px;
}

.modal.left .modal-dialog.modal-sm {
  max-width: 300px;
  height: 100vh;
}

.modal.left .modal-content {
  min-height: 100vh;
  border: 0;
  height: 100vh;
  display: block;
  border-radius: 0;
}

.modal.left .modal-header {
  height: 75px;
}

.modal.left .modal-body {
  height: calc(100vh - 58px);
  overflow: auto;
}

.mobile-menu {
  list-style: none !important;
  padding-left: 5px;
}

.mobile-menu-sub {
  list-style: none !important;
  padding-left: 10px;
}

.mobile-menu li {
  font-size: 20px;
  line-height: 39px;
  list-style: none !important;
}

.mobile-menu-sub li {
  font-size: 20px;
  list-style: none !important;
  padding-left: 1px;
  line-height: 20px;
}

.mobile-menu a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.mobile-menu-sub a {
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}
.link-area > button {
  border: none;
  background: none;
  margin-left: 10px;
  color: #818181;
}
/* Position Style */

.position-lower .top-header {
  height: 39px;
}
.sidebar-menu-tab .nav-tabs {
  border-bottom: none !important;
}
.sidebar-menu-tab .nav-tabs .nav-link.active {
  border-color: transparent !important;
  color: #000000 !important;
}
.sidebar-menu-tab > ul > li > button {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  color: #b3b3b3 !important;
}
/*Contact Section Start*/

.contact-section {
  margin-bottom: 50px;
}

.contact-section-info {
  margin-bottom: 30px;
}

.contact-section-info > h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  text-transform: uppercase;
  color: #1d211f;
}

.contact-section-info > p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  color: #1d211f;
  opacity: 0.8;
}

.contact-section-info > table > tbody > tr {
  height: 35px;
}
.contact-section-info > table > tr > td {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  color: #1d211f;
  opacity: 0.8;
}

.contact-section-message {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 55, 96, 0.1);
  padding: 30px;
}

.contact-section-message-input-group {
  display: flex;
  width: 100%;
  height: 50px;
  border: 1px solid #e8e9e9;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.contact-section-message-input-group > svg {
  width: 16px;
  height: 16px;
  margin: 15px;
}

.contact-section-message-input-group > input {
  border: none;
  background: none;
  width: 100%;
  outline: none !important;
}

.contact-section-message-input-group > input :focus {
  outline: none !important;
}

.contact-section-message-textarea {
  margin-right: -12px;
  height: 180px;
  margin-bottom: 20px;
}

.contact-section-message-textarea > textarea {
  width: 100%;
  height: 100%;
  border: 1px solid #e8e9e9;
  padding: 20px;
  outline: none !important;
}

.contact-section-message-textarea > textarea ::placeholder {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #1d211f;
  opacity: 0.3;
}

.contact-section-message-button {
  float: right;
  margin-right: -12px;
}

.contact-section-message-button > button {
  width: 150px;
  height: 45px;
  background: var(--color-base);
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
/*Contact Section End*/
/*vendor Section Start*/
.vendor-section {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--color-border-bottom);
}
.vendor-section-img {
  height: 100px;
  text-align: center;
}
.vendor-section-info {
  margin-bottom: 10px;
}
.vendor-section-info-head {
  margin-bottom: 15px;
}

.vendor-section-info-head small {
  width: 100%;
}
.vendor-section-info-head p {
  width: 100%;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
.vendor-section-info-head button {
  background-color: #ecb3b02e;
  border: 1px solid var(--color-border-bottom);
  padding: 5px 30px 5px 30px;
  border-radius: 5px;
  position: relative;
  left: 25%;
  color: var(--color-btn-hover);
}
.vendor-section-info-body {
}

.vendor-section-info-body p {
}

.vendor-section-info-body a {
  border: unset;
  background-color: var(--color-base);
  color: white;
  padding: 5px 20px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 12px;
}
.vendor-detail-sidebar {
  background-color: var(--color-base);
  width: 100%;
  border: 1px solid var(--color-border-bottom);
  border-radius: 15px;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  padding: 30px;
  margin-bottom: 30px;
}
.vendor-detail-sidebar-img {
  height: 100px;
  text-align: center;
}
.vendor-detail-sidebar-img img {
  width: 100px;
  height: 100%;
  border-radius: 50%;
}
.vendor-detail-sidebar-info {
  width: 100%;
}
.vendor-detail-sidebar-info small {
}
.vendor-detail-sidebar-info button {
  border: unset;
  font-size: 12px;
}
/*vendor Section End*/

/* Blog-section Start */
.blog-section {
  padding-bottom: 50px;
  background-color: white;
}

.blog-section-item {
  text-align: center;
  width: 100%;
  height: 510px;
  border: 1px solid var(--color-base);
  border-radius: 10px;
  overflow: hidden;
  padding-top: 10px;
}
.blog-section-item img {
  width: 100%;
  height: 51%;
  object-fit: contain;
}
.blog-section-item .blog-section-item-content {
  position: relative;
  background: var(--color-base);
  color: white;
  padding: 10px 10px 30px;
  margin-top: 10px;
}
.blog-section-item .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: uppercase;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.blog-section-item .description {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 20px;
}
.blog-section-item .read-more {
  display: inline-block;
  padding: 7px 20px;
  border: 1px solid white;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  text-transform: capitalize;
  transition: all 0.5s ease 0s;
  text-decoration: none;
}
.blog-section-item .read-more:hover {
  color: var(--color-btn-hover);
  background: white;
  text-decoration: none;
}
.blog-section-item.middle .blog-section-item-content {
  padding: 30px 30px 50px;
}
.blog-detail-section {
  margin-top: 50px;
}
.blog-post-detail-info {
  background-color: white;
  padding: 30px;
}
.blog-post-detail-info > h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  text-transform: uppercase;
  color: #00a69c;
}
.blog-post-detail-info > h2 {
  font-weight: 500;
  font-size: 42px;
  line-height: 40px;
  color: #122f53;
}
.blog-post-detail-info > span {
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  color: #122f53;
  margin-right: 30px;
  margin-bottom: 50px;
}
.blog-post-detail-info > img {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
}
.blog-post-detail-info > p {
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  opacity: 0.7;
}
.blog-post-detail-info > button {
  width: 120px;
  height: 35px;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: #000000;
  text-transform: uppercase;
  background: none;
  margin-right: 30px;
  border: 1px solid rgba(18, 47, 83, 0.2);
  box-sizing: border-box;
}
.blog-post-detail-pagination {
  padding: 30px;
}
.blog-post-detail-pagination > .prev-btn {
  width: 115px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(18, 47, 83, 0.2);
  box-sizing: border-box;
  position: relative;
}
.blog-post-detail-pagination > .next-btn {
  width: 115px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(18, 47, 83, 0.2);
  box-sizing: border-box;
  float: right;
}
.blog-post-detail-pagination > .prev-btn > a {
  text-decoration: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  color: #122f53;
  position: relative;
}
.blog-post-detail-pagination > .next-btn > a {
  text-decoration: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  color: #122f53;
  position: relative;
}
.blog-post-detail-pagination > .prev-btn > a > svg {
  width: 10px;
  margin-right: 15px;
}
.blog-post-detail-pagination > .next-btn > a > svg {
  width: 10px;
  margin-left: 15px;
}
.blog-post-comment {
  padding: 30px;
}
.blog-post-comment > h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  color: #122f53;
}
.blog-post-comment-item {
  display: flex;
  flex-wrap: wrap;
}

.blog-post-comment-item-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background: var(--color-base-font);
  margin-right: 10px;
  margin-left: -6px;
}

.blog-post-comment-item-title {
  width: 83%;
}
.blog-post-comment-item-title > h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #122f53;
}
.blog-post-comment-item-title > p {
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  text-transform: uppercase;
  color: #122f53;
  opacity: 0.5;
}
.blog-post-comment-item-replay {
  width: 10%;
}
.blog-post-comment-item-replay > button {
  border: none;
  background: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  color: #122f53;
}
.blog-post-comment-item-detail {
  padding-left: 7%;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #122f53;
  opacity: 0.7;
  margin-bottom: 15px;
}
.blog-post-comment-item-divider {
  height: 1px;
  background: #d0d5dd;
  width: 100%;
  margin-top: 15px;
}

.blog-post-comment-item-input {
  width: 48%;
  margin-bottom: 30px;
}

.blog-post-comment-item-input > input {
  width: 100%;
  height: 60px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding-left: 21px;
}
.b-p-c-m {
  margin-left: 4%;
}
.blog-post-comment-item-textarea {
  width: 100%;
  margin-bottom: 30px;
}
.blog-post-comment-item-textarea > textarea {
  width: 100%;
  height: 180px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 12px 0 0 21px;
}
.blog-post-comment-item-button {
  width: 100%;
}
.blog-post-comment-item-button > button {
  width: 100%;
  height: 60px;
  background: var(--color-base);
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;
  color: #ffffff;
}
.blog-popular {
  padding: 24px 30px 16px 30px;
  border: 1px solid #d0d5dd;
  margin-bottom: 100px;
  background: white;
}
.blog-feature-title > h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #122f53;
  text-transform: uppercase;
}
.blog-feature-title > hr {
  margin-top: 0;
}
.blog-popular-list > h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  text-transform: uppercase;
  color: var(--color-base-font);
}
.blog-popular-list > a {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #122f53;
  text-decoration: none;
}

/* Blog Section End */
/* About Section Start*/
.about-section-info {
  margin-bottom: 50px;
}
.about-section-info .nav-tabs {
  border-bottom: unset;
}
.about-section-info .nav-link {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #afb8c5 !important;
  padding: 0.5rem 20px 0 0 !important;
  border: unset;
}
.about-section-info .active {
  background: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #122f53 !important;
  border-radius: unset !important;
  border: unset;
}
/* About Section End*/
.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.point-five-delay {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
}
.one-point-two-delay {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.cssanimation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
